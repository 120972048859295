<template>
  <nav
    class="navbar navbar-expand-lg navbar-light header-root"
    :class="{
      'green-header-layout': props?.headerLayout === 'green',
      'unauth-header-root': !userData && layoutType === 'default',
      'unauth-header-root-desktop-login': $route.path == '/desktop-login',
      'p-0':
        userData &&
        $route.path !== '/desktop-login' &&
        !$route.query.u &&
        !$route.query.desktop,
      'info-layout-header-root': layoutType === 'info',
      'desktop-login-header': $route.path == '/desktop-login'
    }"
  >
    <div
      class="container-fluid header-content d-flex flex-row flex-nowrap py-0"
      :class="{ 'p-0': !userData, 'auth-header-content': userData }"
    >
      <NuxtLink
        v-if="!customHeader"
        :class="{ 'cursor-default': userData?.launchType === 'lti' }"
        aria-label="Cambridge Logo"
        class="navbar-brand p-0 me-auto"
        tabindex="0"
        @click="
          userData?.launchType === 'lti' || isDesktopLoginSession
            ? ''
            : navigateToApp()
        "
        @keyup.enter="
          userData?.launchType === 'lti' || isDesktopLoginSession
            ? ''
            : navigateToApp()
        "
      >
        <ResponsiveImage
          v-if="headerLayout === 'green' || $route.path == '/desktop-login'"
          :file="'app-logo.webp'"
          :img="imagesProps['cambridge-logo']"
          pc-image
        />
        <ResponsiveImage
          v-else
          :file="'cambridge-logo-v1.webp'"
          :img="imagesProps['cambridge-logo-v1']"
          pc-image
        />
      </NuxtLink>
      <template v-if="customHeader">
        <slot />
      </template>
      <div
        v-if="
          $route.path != '/desktop-login' &&
          !$route.query.u &&
          !$route.query.desktop &&
          $route.path != '/home' &&
          $route.path != '/' &&
          userData
        "
        class="navbar-nav d-flex flex-row align-items-center h-100"
      >
        <div class="navbar-nav d-flex flex-row align-items-center h-100">
          <div v-if="userData?.launchType !== 'lti'">
            <div
              v-if="userData?.role === 'teacher' || userData?.role === 'admin'"
              class="dropdown position-static help-dropdown-container"
            >
              <button
                id="dropdownMenuButtonUserHelp"
                ref="helpDropdownBtnRef"
                qid="cHeader-8"
                class="btn btn-secondary dropdown-toggle help-dropdown d-flex align-items-center justify-content-center"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="handleHelpBtnClick()"
              >
                <ClientOnly>
                  <nuxt-icon
                    loading="lazy"
                    name="fa6-regular.circle-question"
                    class="fa-circle-question"
                    aria-hidden="true"
                  />
                </ClientOnly>
                <span class="help-text">{{
                  $t('_COMMON_ACROSS_MODULES.HEADER.HELP')
                }}</span>
                <ClientOnly>
                  <nuxt-icon
                    loading="lazy"
                    name="fa-solid.angle-up"
                    class="angle-up-icon ms-1 d-none"
                    aria-hidden="true"
                  />
                </ClientOnly>
                <ClientOnly>
                  <nuxt-icon
                    loading="lazy"
                    name="fa-solid.angle-down"
                    class="fa-angle-down ms-1 d-block"
                    aria-hidden="true"
                  />
                </ClientOnly>
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end dropdown-menu-help"
                aria-labelledby="dropdownMenuButtonUserHelp"
              >
                <div class="dropdown-divider-help" />
                <li class="dropdown-item">
                  <a
                    tabindex="0"
                    qid="cHeader-11"
                    href="javascript:void(0)"
                    class="helpMenu"
                    aria-label="Help"
                    @click="goToHelpPage()"
                  >
                    <span>{{
                      $t('_COMMON_ACROSS_MODULES.HEADER.HELP_CENTRE')
                    }}</span>
                  </a>
                </li>
                <div class="dropdown-divider-help" />
                <li
                  ref="tutorialDropdownBtnRef"
                  class="dropdown dropend"
                  @mouseleave="handleMouseLeave()"
                >
                  <a
                    id="dropdownMenuButtonUserTutorials"
                    tabindex="0"
                    class="helpMenu tutorial-dropdown dropdown-item dropdown-toggle"
                    :aria-label="$t('_COMMON_ACROSS_MODULES.HEADER.TUTORIALS')"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @mouseenter="handleMouseEnter()"
                    @click.stop="handleToggleTutorialsMenu()"
                    @keyup.enter="handleToggleTutorialsMenu()"
                  >
                    <span>{{
                      $t('_COMMON_ACROSS_MODULES.HEADER.TUTORIALS')
                    }}</span>
                    <nuxt-icon
                      name="angle-right-solid"
                      class="tutorial-right-icon ms-2 d-none d-md-block"
                      aria-hidden="true"
                    />
                    <nuxt-icon
                      name="fa-solid.angle-up"
                      class="tutorial-up-icon ms-2 d-none d-md-none"
                      aria-hidden="true"
                    />
                    <nuxt-icon
                      name="angle-down-solid"
                      class="tutorial-down-icon mt-1 ms-2 d-block d-md-none"
                      aria-hidden="true"
                    />
                  </a>
                  <ul
                    ref="tutorialOptionsRef"
                    class="dropdown-menu dropdown-menu-tutorials"
                    aria-labelledby="dropdownMenuButtonUserTutorials"
                  >
                    <div
                      v-for="(tutorialArticle, tutorialKey) in tutorialLinksMap"
                      :key="tutorialKey"
                    >
                      <div class="dropdown-divider-help" />
                      <li class="dropdown-item">
                        <a
                          tabindex="0"
                          rel="noopener"
                          href="javascript:void(0)"
                          class="helpMenu"
                          :aria-label="
                            $t(`_COMMON_ACROSS_MODULES.HEADER.${tutorialKey}`)
                          "
                          @click.stop="
                            goToTutorialHelpLinksPage(tutorialArticle)
                          "
                        >
                          <span class="dropdown-text">{{
                            $t(`_COMMON_ACROSS_MODULES.HEADER.${tutorialKey}`)
                          }}</span>
                        </a>
                      </li>
                    </div>
                  </ul>
                </li>
              </ul>
            </div>
            <div v-else>
              <NuxtLink
                v-if="userData?.launchType !== 'lti'"
                external
                :prefetch="false"
                qid="cHeader-12"
                :to="getHelpPagePath(runtimeConfig.app.appEnv, '', '', locale)"
                target="_blank"
                class="btn btn-main-1 btn-help"
                aria-label="Help"
              >
                <ClientOnly>
                  <nuxt-icon
                    loading="lazy"
                    name="fa6-regular.circle-question"
                    class="fa-circle-question"
                    aria-hidden="true"
                  />
                </ClientOnly>
                <p>{{ $t('_COMMON_ACROSS_MODULES.HEADER.HELP') }}</p>
              </NuxtLink>
            </div>
          </div>
          <LazyServiceNotification />
        </div>
        <div class="dropdown position-static">
          <button
            id="dropdownMenuButtonUserProfile"
            class="btn btn-secondary dropdown-toggle profile-dropdown d-flex align-items-center justify-content-center"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <ClientOnly>
              <nuxt-icon
                loading="lazy"
                name="nemo-user"
                class="user-profile-icon d-flex align-items-center"
                aria-hidden="true"
              />
            </ClientOnly>
            <span class="profile-dropdown-user">{{ userData?.firstName }}</span>
            <ClientOnly>
              <nuxt-icon
                id="angle-down-arrow"
                loading="lazy"
                name="fa-solid.angle-down"
                class="fa-angle-down ms-1"
                aria-hidden="true"
              />
            </ClientOnly>
          </button>

          <ul
            class="dropdown-menu dropdown-menu-end dropdown-menu-user-profile"
            aria-labelledby="dropdownMenuButtonUserProfile"
          >
            <li
              class="dropdown-item dropdown-toggle d-flex align-items-center justify-content-center myProfileName"
            >
              <ClientOnly>
                <nuxt-icon
                  loading="lazy"
                  name="nemo-user"
                  class="user-profile-icon d-flex align-items-center"
                  aria-hidden="true"
                />
              </ClientOnly>
              <span>{{ userData?.firstName }}</span>
              <ClientOnly>
                <nuxt-icon
                  id="angle-up-arrow"
                  loading="lazy"
                  name="fa-solid.angle-up"
                  class="fa-angle-up"
                  aria-hidden="true"
                />
              </ClientOnly>
            </li>
            <div class="dropdown-divider" />
            <template v-if="userData?.launchType !== 'lti'">
              <li class="dropdown-item">
                <button
                  qid="cHeader-5"
                  class="dropdown-item-btn"
                  @click="navigateToDashboardApp(['my-profile'])"
                >
                  {{ $t('_COMMON_ACROSS_MODULES.HEADER.MY_PROFILE') }}
                </button>
              </li>
              <div class="dropdown-divider" />
              <li v-if="userData?.role == 'teacher'" class="dropdown-item">
                <button
                  qid="cHeader-6"
                  class="dropdown-item-btn"
                  @click="
                    navigateToDashboardApp(
                      ['teacher', 'startteaching', 'joinSchool'],
                      { queryParams: { joinAnotherInstitute: true } }
                    )
                  "
                >
                  {{ $t('_COMMON_ACROSS_MODULES.HEADER.JOIN_NEW_SCHOOL') }}
                </button>
              </li>
              <div
                v-if="userData?.role == 'teacher'"
                class="dropdown-divider"
              />
              <li class="dropdown-item-logout">
                <button
                  qid="cHeader-7"
                  class="btn btn-xs btn-white-bordered logout"
                  @click="logout()"
                >
                  {{ $t('_COMMON_ACROSS_MODULES.HEADER.LOGOUT') }}
                </button>
              </li>
            </template>
            <template v-else>
              <li class="dropdown-item-logout">
                <button
                  qid="cHeader-9"
                  class="btn btn-xs btn-white-bordered logout"
                  @click="logout()"
                >
                  {{ $t('_COMMON_ACROSS_MODULES.HEADER.LOGOUT') }}
                </button>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div
        v-else-if="
          (userData == false || $route.query.u || $route.query.desktop) &&
          $route.path != '/desktop-login'
        "
        class="navbar-nav d-flex flex-row align-items-center h-100 login-lang-btn-container"
        :class="{ 'py-2': !userData && layoutType === 'dafault' }"
      >
        <a
          id="onboarding-header-login-btn"
          class="btn btn-white btn-xs login-btn ms-1"
          tabindex="0"
          href="javascript:void(0)"
          :class="{
            'login-btn-main': $route.path == '/social-login',
            'd-none': $route.path == '/parental-consent',
            'btn-white': $route.path == '/home' || $route.path == '/',
            'btn-white-bordered text-white login-button-other-pages':
              props?.headerLayout !== 'green',
            'green-header-layout-login-btn': props?.headerLayout === 'green'
          }"
          @click="getLoginScreen()"
        >
          {{ $t('ONBOARDING.HOME.ALREADY_REGISTERED') }}
        </a>
        <div class="dropdown">
          <button
            data-bs-toggle="dropdown"
            class="language-dropdown btn btn-link"
            :class="
              ($route.path != '/home' &&
                $route.path != '/' &&
                layoutType !== 'info' &&
                props?.headerLayout !== 'green' &&
                'language-dropdown-other-pages') ||
              (layoutType === 'info' && 'info-layout-language-dropdown')
            "
            tabindex="0"
          >
            <ClientOnly>
              <nuxt-icon
                loading="lazy"
                name="nemo.globe"
                class="nemo-globe"
                :class="
                  props?.headerLayout === 'green'
                    ? 'green-layout-nemo-globe'
                    : 'text-white'
                "
                aria-hidden="true"
              />
            </ClientOnly>
            <span
              qid="header-10"
              class="font-xs font-weight-normal"
              :class="[
                props?.headerLayout === 'green'
                  ? 'green-layout-nemo-language-text ms-0'
                  : 'text-white ms-1',
                {
                  'd-none-mobile':
                    $route.path === '/home' || $route.path === '/'
                }
              ]"
            >
              {{ locale }}
            </span>
          </button>

          <div
            aria-labelledby="
              dropdownMenuLink"
            class="dropdown-menu language-btn"
          >
            <div class="dropdown-menu-arrow" />
            <a
              v-for="localee in locales"
              :key="localee.code"
              href="javascript:void(0)"
              :class="{ 'selected-item': localee.code == locale }"
              class="text-center lang-dropdown-item dropdown-item"
              @click="changeLang(localee.code)"
            >
              {{ localee.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
const route = useRoute();
const { addQueryParams } = useHttpHelper();
const queryParams = route.query;
const allowedQueryParams = useRuntimeConfig().public.ielts.allowedQueryParams;
const userData = ref();
const isDesktopLoginSession = ref();
const helpDropdownBtnRef = shallowRef();
const tutorialOptionsRef = shallowRef();
const tutorialDropdownBtnRef = shallowRef();
const runtimeConfig = useRuntimeConfig();
const { getHelpPagePath } = useHelpConfig();
const { navigateToDashboardApp } = useInterAppNavigationService();
const { checkUserAuthenticatedCache, getUserData } = useOnboardingAuth();
onBeforeMount(() => {
  checkUserAuthenticatedCache().then((resp) => {
    if (resp && route.path != '/parental-consent') {
      userData.value = getUserData();
    } else {
      userData.value = false;
    }
  });
});

if (process.client) {
  if (
    sessionStorage.getItem('desktop-login-token') ||
    route.query.desktop ||
    route.query.u
  ) {
    isDesktopLoginSession.value = true;
  }
}

// tutorial links mapping
const tutorialLinksMap = {
  UNDERSTANDING_CODES_AND_KEYS: 'understandingCodesAndKeys',
  ACTIVATING_COURSE_MATERIALS: 'activatingCourseMaterials',
  CREATING_A_CLASS: 'creatingClass',
  ADDING_STUDENTS_TO_A_CLASS: 'addingStudentsToClass',
  CREATING_ASSIGNMENTS: 'creatingAssignments'
};

//implement logout logic
const logout = async () => {
  const { logOut } = useAuthService();
  const { success } = await logOut();
  if (success) {
    navigateTo({ path: '/home' });
  }
};

/**
 * Redirects to the help page
 */
const goToHelpPage = () => {
  window.open(
    getHelpPagePath(runtimeConfig.app.appEnv, '', '', locale.value),
    '_blank'
  );
};

const { locale, locales, setLocale } = useI18n();
const { showOverlayLoader, hideLoader } = useLoaderService();
const { lang: language } = useLocalStorage();

const changeLang = async (lang) => {
  showOverlayLoader();
  window.localStorage.setItem(language, lang.toLowerCase());
  await setLocale(lang);
  hideLoader();
  if (runtimeConfig.public.gigyaScreens[route.path]) {
    window.location.reload();
  }
};

const props = defineProps({
  uiProps: {
    type: Boolean,
    default: true
  },
  customHeader: {
    type: Boolean,
    default: false
  },
  layoutType: {
    type: String,
    default: 'default'
  },
  headerLayout: {
    type: String,
    default: 'purple'
  }
});
const imagesProps = {
  'cambridge-logo': {
    class: `${(route.path == '/home' || route.path == '/') && 'brand-img-home'} brand-img`,
    alt: 'Cambridge Logo',
    width: 140,
    height: 24
  },
  'cambridge-logo-v1': {
    class: `${(route.path == '/home' || route.path == '/') && 'brand-img-home'} brand-img`,
    alt: 'Cambridge Logo',
    width: 140,
    height: 40
  },
  'long-arrow-right': {
    class: 'd-none d-md-inline',
    'aria-hidden': 'true'
  }
};

const navigateToApp = () => {
  if (route.query.ielts) {
    const redirectUrl = useNavigation().constructMicroAppUrl(
      ['home'],
      useRuntimeConfig().public.microapps.urlContracts.ielts
    );
    window.location.href = redirectUrl;
  } else if (route.path == '/parental-consent') {
    return;
  } else {
    navigateTo(addQueryParams('/home', { queryParams, allowedQueryParams }));
  }
};
const getLoginScreen = () => {
  let screenPath;
  if (location.pathname.includes('/login-primary')) {
    screenPath = '/login-primary';
  } else if (location.pathname.includes('/login-secondary')) {
    screenPath = '/login-secondary';
  } else {
    screenPath = '/login';
  }

  if (document.getElementById('c1-gigya-container')) {
    const screenId = useRuntimeConfig().public.gigyaScreens[screenPath];
    if (window['gigya']) initGigyaScreen('', screenId, 'c1-gigya-container');
    return;
  }
  navigateTo(addQueryParams(screenPath, { queryParams, allowedQueryParams }));
};

/**
 * Handles the click event on the help dropdown button and hides the tutorials menu
 * Also adds 'help-dropdown-open' class to the help dropdown button which is used to show up and down icons
 */
const handleHelpBtnClick = () => {
  // hide the tutorials menu
  handleHideTutorialsMenu();

  if (helpDropdownBtnRef.value.classList.contains('show')) {
    helpDropdownBtnRef.value?.classList.add('help-dropdown-open');
  } else {
    helpDropdownBtnRef.value?.classList.remove('help-dropdown-open');
  }
};

/**
 * Redirects to the tutorial help links page and hide the tutorials dropdown menu
 */
const goToTutorialHelpLinksPage = (article) => {
  // hide the tutorials menu
  handleHideTutorialsMenu();
  // navigate to the tutorial help links page
  window.open(
    getHelpPagePath(runtimeConfig.app.appEnv, 'teacher', article, locale.value),
    '_blank'
  );
};

/**
 * Triggered when mouse enters the tutorials dropdown button
 * Case 1: If the device is desktop, show the tutorials menu
 * Case 2: If the device is mobile or tablet, do nothing
 */
const handleMouseEnter = () => {
  if (window.innerWidth >= 768) {
    handleShowTutorialsMenu();
  }
};

/**
 * Triggered when mouse leaves the tutorials dropdown button for all screens/devices
 */
const handleMouseLeave = () => {
  handleHideTutorialsMenu();
};

/**
 * Shows the tutorials menu by adding 'show' class to the tutorial options
 * Also adds 'tutorial-dropdown-open' class to the tutorial dropdown button which is used to show up and down icons
 */
const handleShowTutorialsMenu = () => {
  tutorialDropdownBtnRef.value?.classList.add('tutorial-dropdown-open');
  tutorialOptionsRef.value?.classList.add('show');
};

/**
 * Hides the tutorials menu by removing 'show' class from the tutorial options
 * Removes 'tutorial-dropdown-open' class from the tutorial dropdown button
 */
const handleHideTutorialsMenu = () => {
  tutorialDropdownBtnRef.value?.classList.remove('tutorial-dropdown-open');
  tutorialOptionsRef.value?.classList.remove('show');
};

/**
 * Case 1: If the device is mobile or tablet, toggle the tutorials dropdown menu
 * Case 2: If the device is desktop, show the tutorials dropdown menu only(does not toggle)
 */
const handleToggleTutorialsMenu = () => {
  if (
    tutorialOptionsRef.value?.classList.contains('show') ||
    window.innerWidth >= 768
  ) {
    handleShowTutorialsMenu();
  } else {
    handleHideTutorialsMenu();
  }
};
</script>
